<template>
  <v-app>
    <v-card>
      <v-img
        class="fill-height"
        :aspect-ratio="16 / 9"
        height="100vh"
        width="100vw"
      >
        <v-container fill-height v-if="!loading">
          <v-row justify="center" align="center">
            <v-col cols="12" md="6" class="text-center">
              <v-img
                :src="siteAppURL + '/images/logo.png'"
                class="mx-auto"
                width="200"
              ></v-img>
              <br /><br /><br />
              <p class="mt-5 pt-5 error-text">
                404 お探しのページは存在しません。
              </p>
              <router-link class="error-subtext" :to="{ name: 'Quote' }">
                トップページに戻る
              </router-link>
            </v-col>
          </v-row>
        </v-container>
      </v-img>
    </v-card>

    <v-container fluid class="error-page">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="12">
          <v-row>
            <div class="logo-wrapper mt-5 pt-5" style="width: 100%"></div>
          </v-row>
          <div class="card">
            <br /><br /><br />
            <!-- <span class="error-logo">404</span> -->
            <p class="error-text">
              404 お探しのページは存在しません。
            </p>
            <p class="error-subtext">
              トップページに戻る
            </p>

            <router-link
              class="text-capitalize"
              dark
              x-large
              :color="config.light.primary"
              :to="{ name: 'Quote' }"
            >
              家に帰る
            </router-link>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import config from '@/config'

export default {
  name: 'Error',
  data() {
    return {
      siteAppURL: process.env.VUE_APP_URL,
      config
    }
  }
}
</script>

<style src="./Error.scss" lang="scss"></style>
